<template>
    <div class="sector">
        <div class="sector-head">
            <h1 class="sector-head-title">{{ titles.sector }}</h1>
        </div>
        <div class="form-field-plus">
            <div class="field-plus-legend">Clique para vincular</div>
            <i class="fa fa-long-arrow-right arrow1" aria-hidden="true"></i>
            <div>
                <v-btn fab dark color="secondary" @click="goTo()">
                    <v-icon dark> mdi-account-plus </v-icon>
                </v-btn>
            </div>
        </div>
        <v-alert
            text
            dense
            color="gray"
            icon="mdi-alert-circle-outline"
            border="left"
            v-if="!managers.length">
            Clique no botão abaixo para vincular profissionais!
        </v-alert>
        <div class="box-table" v-else>
            <v-data-table
                :headers="headers"
                :items="managers"
                :page.sync="page"
                sort-by="name"
                :items-per-page="itemsPerPage"
                hide-default-footer
                class="data-table elevation-2"
                @page-count="pageCount = $event">
                <template v-slot:[`item.avatar`]="{}">
                    <v-avatar color="#dddddd" size="40"></v-avatar>
                </template>
                <template v-slot:[`item.activated`]="{ item }">
                    <v-icon :color="getIconActivatedComputed(item.activated).color">
                        {{ getIconActivatedComputed(item.activated).icon }}
                    </v-icon>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <div v-if="action && item.id === itemIdActivated" class="confirm">
                        <div>Confirma ação?</div>
                        <div>
                            <v-btn
                                color="secondary"
                                class="btn-action option confirm-ok"
                                x-small
                                elevation="0"
                                rounded
                                dark
                                @click="actionConfirmUnlink(item.id, true)">
                                Sim
                            </v-btn>
                            <v-btn
                                color="red"
                                class="btn-action option"
                                x-small
                                elevation="0"
                                rounded
                                dark
                                @click="actionConfirmUnlink(item.id, false)">
                                Não
                            </v-btn>
                        </div>
                    </div>
                    <v-btn
                        v-else
                        color="red"
                        class="btn-action vinculate"
                        small
                        rounded
                        plain
                        elevation="1"
                        dark
                        @click="actionUnlink(item.id)">
                        <v-icon left> mdi-account-minus </v-icon>
                        Desvincular
                    </v-btn>
                </template>
            </v-data-table>
            <div class="text-center pt-2">
                <v-pagination v-model="page" :length="pageCount"></v-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import { isAmbientGeralTests } from '@/configs/global.config';
    import managersMock from '@/modules/company/mocks/managers.company.mock';

    export default {
        name: 'ListManager',
        data() {
            return {
                isAmbientLocalTests: false,
                page: 1,
                pageCount: 0,
                itemsPerPage: 10,
                action: null,
                itemIdActivated: null,
                titles: {
                    sector: 'Profissionais'
                },
                headers: [
                    {
                        text: 'Foto',
                        align: 'start',
                        sortable: false,
                        value: 'avatar'
                    },
                    { text: 'Nome', value: 'name' },
                    { text: 'Email', value: 'email' },
                    { text: 'Conta ativada?', value: 'activated', align: 'center' },
                    { text: 'Ações', value: 'actions', align: 'center', sortable: false }
                ],
                managers: []
            };
        },
        mounted() {
            this.populateGeral();
        },
        computed: {
            getIconActivatedComputed() {
                return (activated) => {
                    return {
                        icon: 'mdi-email-check',
                        color: activated ? 'green' : '#d0d0d0'
                    };
                };
            }
        },
        methods: {
            isAmbientTests() {
                return isAmbientGeralTests && this.isAmbientLocalTests;
            },
            populateGeral() {
                if (this.isAmbientTests()) {
                    this.managers = managersMock.managers;
                } else {
                    this.populateManagers();
                }
            },
            mockActionUnlink(userLinkedId) {
                this.managers = this.managers.filter((u) => u.id !== userLinkedId);
            },
            efectiveUnLink(userLinkedId) {
                this.$store.commit('setGlobalLoading', true);

                this.$service.http['company']
                    .unLinkUser(userLinkedId)
                    .then((_) => {
                        this.populateManagers();
                    })
                    .catch((e) => {
                        console.error(e);
                    })
                    .finally((_) => {
                        this.$store.commit('setGlobalLoading', false);
                    });
            },
            actionUnlink(managerLinkedId) {
                this.action = true;
                this.itemIdActivated = managerLinkedId;
            },
            actionConfirmUnlink(managerLinkedId, confirm) {
                if (confirm) {
                    if (this.isAmbientTests()) {
                        this.mockActionUnlink(managerLinkedId);
                    } else {
                        this.efectiveUnLink(managerLinkedId);
                    }
                } else {
                    this.action = false;
                    this.itemIdActivated = null;
                }
            },
            goTo() {
                this.$router.push({ name: 'findManagersCompany' });
            },
            dataTransform(data) {
                const managers = [];

                data.forEach((e) => {
                    managers.push({
                        id: e.id,
                        name: e.user.name,
                        email: e.user.email,
                        activated: e.user.activated
                    });
                });

                return managers;
            },
            populateManagers() {
                this.$store.commit('setGlobalLoading', true);

                this.$service.http['company']
                    .getListUsersLinkedByRole('MANAGER')
                    .then((result) => {
                        const records = result.data.docs;

                        this.managers = this.dataTransform(records);
                    })
                    .catch((e) => {
                        console.error(e);
                    })
                    .finally((_) => {
                        this.$store.commit('setGlobalLoading', false);
                    });
            }
        }
    };
</script>

<style scoped>
    .confirm {
        padding: 5px;
    }

    .confirm-ok {
        margin-right: 10px;
    }

    .btn-action {
        text-transform: none !important;
        font-weight: bold;
    }

    .vinculate {
        width: 130px;
    }

    .option {
        width: 30px !important;
    }

    .form-field-plus {
        display: flex;
        position: fixed;
        align-items: center;
        right: 15px;
        bottom: 60px;
        z-index: 1000;
    }

    .field-plus-legend {
        font-size: 14px;
        color: #777777;
    }

    @keyframes slide1 {
        0%,
        100% {
            transform: translate(0, 0);
        }

        50% {
            transform: translate(10px, 0);
        }
    }

    .arrow1 {
        animation: slide1 1s ease-in-out infinite;
        margin-left: 10px;
        margin-right: 20px;
    }

    .sector-head {
        margin-bottom: 15px;
    }

    .sector-head-title {
        text-align: left !important;
    }

    .v-data-table-header {
        background-color: #d0d0d0;
    }
</style>
