export default {
    managers: [
        {
            id: 1,
            avatar: 'foto.jpg',
            name: 'Tiago Santos',
            groups: 3,
            campaigns: 8,
            email: 'tiagosantos@gmail.com',
            activated: false
        },
        {
            id: 2,
            avatar: 'foto2.jpg',
            name: 'Cintia Tavares',
            groups: 3,
            campaigns: 8,
            email: 'cintiatav@gmail.com',
            activated: true
        }
    ]
};
